body {
  padding: 0;
  margin: 0;
}
.container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}


.gamespace {
  background: linear-gradient(
    0deg, #c1eefd, #3cb8e1);
  flex-basis: 100%;
  position: relative;
  width: 100%;
}

.player {
  position: absolute;
  background-color: #000;
  border-radius: 5px;
}

.floor {
  padding: 5px;
  width: 100%;
  background-color: green;
  height: 5px;
  box-sizing: border-box;
}